import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import Block, { newBlock } from "../../models/blocks";

interface BlockFormProps {
  initialBlock?: Block;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const BlockForm: React.FC<BlockFormProps> = ({
  initialBlock,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingBlock, setEditingBlock] = useState<Block>(
    initialBlock ? initialBlock : newBlock()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBlock({
      ...editingBlock,
      identifier: e.target.value,
    });
  };

  const onCustomLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingBlock({
      ...editingBlock,
      customLabel: e.target.value,
    });
  };

  const onLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let latitude: number | undefined = undefined;
    if (!isNaN(parseFloat(e.target.value))) {
      latitude = parseFloat(e.target.value);
    }
    let newEditingItem: Block = { ...editingBlock };
    if (latitude) {
      newEditingItem.latitude = latitude;
      setEditingBlock(newEditingItem);
    }
  };

  const onLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let longitude: number | undefined = undefined;
    if (!isNaN(parseFloat(e.target.value))) {
      longitude = parseFloat(e.target.value);
    }
    let newEditingItem: Block = { ...editingBlock };
    if (longitude) {
      newEditingItem.longitude = longitude;
      setEditingBlock(newEditingItem);
    }
  };

  const onSave = async () => {
    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<Block>>;
    if (editingBlock.id === undefined) {
      requestPromise = createItem<Block>("/blocks/create/", editingBlock);
    } else {
      requestPromise = updateItem<Block>(
        `/blocks/${editingBlock.id}/`,
        editingBlock
      );
    }

    const clientStatus = await requestPromise;

    if (clientStatus.status !== SUCCESS) {
      if (clientStatus.errors !== undefined) {
        setErrors(clientStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (clientStatus.detail !== undefined) {
        message = clientStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingBlock(newBlock());
  };

  useEffect(() => {
    setEditingBlock(initialBlock ? initialBlock : newBlock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBlock]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingBlock.identifier)}
                onChange={onIdentifierChange}
                placeholder="Nombre de Loteamiento"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Latitud:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={editingBlock.latitude || ""}
                onChange={onLatitudeChange}
                step={"0.000001"}
                key={editingBlock.latitude}
                placeholder="Latitud"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("latitude", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Longitud:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={editingBlock.longitude || ""}
                onChange={onLongitudeChange}
                step={"0.000001"}
                key={editingBlock.longitude}
                placeholder="Longitud"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("longitude", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Label Custom:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingBlock.customLabel)}
                onChange={onCustomLabelChange}
                placeholder="Etiqueta Custom (max 2 Caracteres)"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("customLabel", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default BlockForm;
