import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  Tooltip,
  useMap,
  Marker,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression, LatLngBounds } from "leaflet";
import { useLocation, useParams } from "react-router-dom";
import { getItem, getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import Plot from "../../models/plots";
import { Spinner } from "react-bootstrap";
import LandDevelopment from "../../models/land_development";
import Block from "../../models/blocks";
import {
  CBadge,
  CButton,
  CButtonClose,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CLink,
  CModalHeader,
  CRow,
} from "@coreui/react";
import { formatToCurrency } from "../../currency/format";
import { PYG } from "../../currency/available-currencies";
const PLOTS_LIMIT = 10000; //basically unlimitted
const TOOLTIP_TIME = 15000;
const MapPage: React.FC = () => {
  const [landDevelopment, setLandDevelopment] = useState<
    LandDevelopment | undefined
  >(undefined);
  const [plots, setPlots] = useState<Plot[]>([]);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [bounds, setBounds] = useState<LatLngBounds | null>(null);
  const [loading, setLoading] = useState(true);
  const [zoomLevel, setZoomLevel] = useState<number | undefined>();

  const [activeTooltip, setActiveTooltip] = useState<number | null>(null);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const getInitialValues = async () => {
    setLoading(true);
    const plotsPromise = fetchPlots(Number(id));
    const blocksPromise = fetchBlocks(Number(id));
    const landDevelopmentPromise = fetchLandDevelopment(Number(id));
    await plotsPromise;
    await blocksPromise;
    await landDevelopmentPromise;
    setLoading(false);
  };

  const fetchLandDevelopment = async (id: number) => {
    const landDevelopmentStatus = await getItem<LandDevelopment>(
      `/land_developments/${id}/`
    );
    if (landDevelopmentStatus.status === SUCCESS) {
      if (landDevelopmentStatus.data !== undefined) {
        setLandDevelopment(landDevelopmentStatus.data);
      }
    } else {
      const message = landDevelopmentStatus.detail
        ? landDevelopmentStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchPlots = async (id: number) => {
    const urlParams = new Map();
    const limit = PLOTS_LIMIT;
    const offset = 0;
    urlParams.set("land_development_id", id.toString());
    const plotsStatus = await getList<Plot>(
      "/plots/",
      limit,
      offset,
      urlParams
    );
    if (plotsStatus.status === SUCCESS) {
      if (plotsStatus.data !== undefined) {
        const fetchedPlots = plotsStatus.data.items;
        setPlots(fetchedPlots);
        calculateBounds(fetchedPlots);
      }
    } else {
      const message = plotsStatus.detail
        ? plotsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const fetchBlocks = async (id: number) => {
    const urlParams = new Map();
    const limit = PLOTS_LIMIT;
    const offset = 0;
    urlParams.set("land_development_id", id.toString());
    const blocksStatus = await getList<Plot>(
      "/blocks/",
      limit,
      offset,
      urlParams
    );
    if (blocksStatus.status === SUCCESS) {
      if (blocksStatus.data !== undefined) {
        const fetchedPlots = blocksStatus.data.items;
        setBlocks(fetchedPlots);
      }
    } else {
      const message = blocksStatus.detail
        ? blocksStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const calculateBounds = (plots: Plot[]) => {
    if (plots.length === 0) return;

    const latitudes: number[] = [];
    const longitudes: number[] = [];

    plots.forEach((plot) => {
      plot.plotPoints?.forEach((point) => {
        if (point.latitude !== undefined && point.longitude !== undefined) {
          latitudes.push(point.latitude);
          longitudes.push(point.longitude);
        }
      });
    });

    if (latitudes.length > 0 && longitudes.length > 0) {
      const minLat = Math.min(...latitudes);
      const maxLat = Math.max(...latitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      const newBounds = new LatLngBounds([minLat, minLng], [maxLat, maxLng]);

      setBounds(newBounds);
    }
  };

  const handleTooltipOpen = (plotIndex: number) => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }

    if (plotIndex !== activeTooltip) setActiveTooltip(plotIndex);

    const timeout = setTimeout(() => {
      setActiveTooltip(null);
    }, TOOLTIP_TIME);

    setTooltipTimeout(timeout);
  };

  useEffect(() => {
    if (id === undefined || isNaN(Number(id))) {
      setLoading(false);

      return;
    }
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Spinner
        animation="grow"
        style={{
          height: "17px",
          width: "17px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "10px",
        }}
      />
    );
  }
  const mapHeight = location.pathname.includes("/internal_map/")
    ? "100vh"
    : "75vh";

  const ZoomListener = () => {
    useMapEvents({
      zoomend: (e) => {
        setZoomLevel(e.target.getZoom());
      },
    });
    return null;
  };

  const createCustomIcon = (number: string) => {
    return L.divIcon({
      html: `
          <div style="
            font-size: 32px; 
            color: white; 
            font-weight: bold; 
            text-shadow: 
              -1px -1px 0 black,  
               1px -1px 0 black,  
               -1px  1px 0 black,  
               1px  1px 0 black;
            ">
            ${number}
          </div>`,
      className: "custom-marker",
      iconSize: [40, 40], // Ajusta el tamaño del contenedor
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <style>
        {`
          .leaflet-container {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none;   /* Safari */
            -khtml-user-select: none;    /* Konqueror HTML */
            -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* Internet Explorer/Edge */
            user-select: none;           /* Chrome, Edge, Opera, Firefox */
            outline: none;               /* Eliminar contorno del enfoque */
          }

          .leaflet-container:focus {
            outline: none;               /* Asegurarse de que no haya contorno al enfocarse */
          }

          /* Esto es opcional, pero puede ser útil para asegurar que no haya estilos heredados */
          .leaflet-container * {
            outline: none !important;     /* Eliminar contornos de todos los elementos dentro del contenedor del mapa */
          }
        `}
      </style>
      <MapContainer
        center={[51.51, -0.12]}
        zoom={13}
        style={{ height: mapHeight, width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <ZoomListener />
        {bounds && <MapBoundsSetter bounds={bounds} />}
        {blocks.map((block) => {
          if (block.latitude === undefined || block.longitude === undefined) {
            return null;
          }
          if ((zoomLevel || 13) < 17) {
            return null;
          }
          const markerCoordinates: LatLngExpression = [
            block.latitude,
            block.longitude,
          ];

          const label: string = block.customLabel
            ? block.customLabel
            : block.order!.toString();

          return (
            <Marker
              key={block.id}
              position={markerCoordinates}
              icon={createCustomIcon(label)}
            />
          );
        })}
        {plots.map((plot, index) => {
          if (!plot.plotPoints || plot.plotPoints.length < 3) {
            return null;
          }
          const sortedPoints = plot.plotPoints.sort(
            (a, b) => (a.order ?? 0) - (b.order ?? 0)
          );

          const polygonCoordinates: LatLngExpression[] = sortedPoints.map(
            (point) => [point.latitude ?? 0, point.longitude ?? 0]
          );

          const fillColor = plot.isTaken ? "blue" : "green";

          return (
            <Polygon
              key={index}
              positions={polygonCoordinates}
              pathOptions={{
                fillColor: fillColor,
                color: activeTooltip === index ? "orange" : fillColor,
                weight: activeTooltip === index ? 4 : 1,
              }}
              eventHandlers={{
                mouseover: () => handleTooltipOpen(index),
              }}
            >
              <Tooltip
                key={index.toString() + (activeTooltip || "")}
                interactive
                permanent={activeTooltip === index}
              >
                <CCard
                  style={{ padding: 0, width: "300px", fontSize: "0.73em" }}
                >
                  <CCardHeader>
                    <CButtonClose
                      onClick={() => {
                        setActiveTooltip(null);

                        setTooltipTimeout(null);
                      }}
                    />
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol sm={7}>
                        <p>
                          <CBadge
                            color={plot.isTaken ? "info" : "success"}
                            style={{ fontSize: "1em" }}
                          >
                            {plot.isTaken ? "Vendido" : "Disponible"}
                          </CBadge>
                        </p>
                        <p>{plot.blockIdentifier}</p>
                        <h5>{plot.identifier}</h5>
                        <p>
                          Cuotas Desde:{" "}
                          {!plot.isTaken && plot.monthlyPayment !== undefined
                            ? formatToCurrency(plot.monthlyPayment, PYG)
                            : "-"}
                        </p>
                        <p>
                          Plazo:{" "}
                          {plot.paymentsAmount !== undefined
                            ? plot.paymentsAmount
                            : "-"}
                        </p>
                        <p>
                          Superficie:{" "}
                          {plot.surface !== undefined ? plot.surface : "-"}
                        </p>
                      </CCol>
                      <CCol
                        sm={5}
                        className="d-flex flex-column justify-content-around "
                      >
                        {!plot.isTaken ? (
                          <CButton
                            size="sm"
                            color="success"
                            className="mb-2 text-white"
                            href={
                              landDevelopment?.description
                                ? landDevelopment?.description
                                : "#"
                            }
                            target="_blank"
                            style={{ fontSize: "1.2em" }}
                          >
                            <i className="fa fa-brands fa-whatsapp"></i>{" "}
                            Contactar
                          </CButton>
                        ) : null}
                        <CButton
                          size="sm"
                          color="primary"
                          className="mb-2 text-white"
                          href={
                            landDevelopment?.googleMapsUrl
                              ? landDevelopment?.googleMapsUrl
                              : "#"
                          }
                          target="_blank"
                          style={{ fontSize: "1.2em" }}
                        >
                          <i className="fa fa-map"></i> Google Maps
                        </CButton>
                        <CButton
                          size="sm"
                          color="primary"
                          className="mb-2 text-white"
                          href={
                            landDevelopment?.siteUrl
                              ? `http://wa.me/?text=${landDevelopment.siteUrl}`
                              : "#"
                          }
                          target="_blank"
                          style={{ fontSize: "1.2em" }}
                        >
                          <i className="fa fa-share"></i> Compartir
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </Tooltip>
            </Polygon>
          );
        })}
      </MapContainer>
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        <div>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "blue",
              marginRight: "5px",
            }}
          ></span>
          Vendido
        </div>
        <div>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "green",
              marginRight: "5px",
            }}
          ></span>
          Disponible
        </div>
      </div>
    </div>
  );
};

const MapBoundsSetter: React.FC<{ bounds: LatLngBounds }> = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  return null;
};

export default MapPage;
