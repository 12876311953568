import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createItem,
  updateItem,
  ItemRequestStatus,
  getList,
  getItem,
} from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert, warningAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import {
  UniqueListItem,
  listToUniqueListItems,
  newUniqueListItem,
  uniqueListToListItems,
} from "../../models/unique-list-item";
import PlotPointForm from "./PlotPointForm";
import Plot, { newPlot, PlotPoint } from "../../models/plots";
import Block from "../../models/blocks";
import BlockSelect from "../blocks/BlockSelect";
import { useParams } from "react-router-dom";
import CurrencyField from "../currencies/CurrencyField";
import { PYG } from "../../currency/available-currencies";

interface SaleOrderFormProps {
  initialPlot?: Plot;
  initialErrors?: Errors;
  landDevelopmentId?: number;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const FIXED_COLUMNS = ["Latitud", "Longitud", "Borrar"];

const PlotForm: React.FC<SaleOrderFormProps> = ({
  initialPlot,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const { id } = useParams<{ id: string }>();

  const [editingPlot, setEditingPlot] = useState<Plot>(
    initialPlot ? initialPlot : newPlot()
  );
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const [plotPoints, setPlotPoints] = useState<UniqueListItem<PlotPoint>[]>([]);

  const onBlockChange = (newBlock: Block | null) => {
    setSelectedBlock(newBlock);
    setEditingPlot((editingPlot) => {
      return {
        ...editingPlot,
        blockId: newBlock !== null ? newBlock.id : undefined,
        blockIdentifier: newBlock !== null ? newBlock.identifier : undefined,
      };
    });
  };

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingPlot({
      ...editingPlot,
      identifier: e.target.value,
    });
  };

  const onRemoteIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let remoteId: number | undefined = undefined;
    if (!isNaN(parseInt(e.target.value))) {
      remoteId = parseInt(e.target.value);
    }
    let newEditingItem: Plot = { ...editingPlot };
    if (remoteId) {
      newEditingItem.remoteId = remoteId;
      setEditingPlot(newEditingItem);
    }
  };

  const onSurfaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let surface: number | undefined = undefined;
    if (!isNaN(parseFloat(e.target.value))) {
      surface = parseFloat(e.target.value);
    }
    let newEditingItem: Plot = { ...editingPlot };
    if (surface) {
      newEditingItem.surface = surface;
      setEditingPlot(newEditingItem);
    }
  };

  const onPaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let amount: number | undefined = undefined;
    if (!isNaN(parseInt(e.target.value))) {
      amount = parseInt(e.target.value);
    }
    let newEditingItem: Plot = { ...editingPlot };
    if (amount) {
      newEditingItem.paymentsAmount = amount;
      setEditingPlot(newEditingItem);
    }
  };

  const onMonthlyAmountChange = (newAmount: number | undefined) => {
    setEditingPlot({
      ...editingPlot,
      monthlyPayment: newAmount,
    });
  };

  const onTotalAmountChange = (newAmount: number | undefined) => {
    setEditingPlot({
      ...editingPlot,
      totalPayment: newAmount,
    });
  };

  const onAddItemClick = useCallback(() => {
    const newPlotPoints = [...plotPoints, newUniqueListItem<PlotPoint>({})];

    setPlotPoints(newPlotPoints);
  }, [plotPoints]);

  const onPlotPointItemEntryChange = useCallback(
    (plotPoint: UniqueListItem<PlotPoint>) => {
      let newPlotPoints = [...plotPoints];
      const index = newPlotPoints.findIndex((item) => {
        return item.uuid === plotPoint.uuid;
      });

      if (index === -1) {
        return;
      }

      newPlotPoints[index] = plotPoint;

      setPlotPoints(newPlotPoints);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plotPoints]
  );

  const onPlotPointItemEntryDelete = useCallback(
    (entry: UniqueListItem<PlotPoint>) => {
      let newPlotPoints = [...plotPoints];
      const index = newPlotPoints.findIndex((item) => {
        return item.uuid === entry.uuid;
      });

      if (index === -1) {
        return;
      }

      newPlotPoints.splice(index, 1);

      setPlotPoints(newPlotPoints);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [plotPoints]
  );

  const fetchBlock = async (id: number) => {
    const blockStatus = await getItem<Block>(`/blocks/${id}/`);
    if (blockStatus.status === SUCCESS) {
      if (blockStatus.data !== undefined) {
        setSelectedBlock(blockStatus.data);
      }
    } else {
      const message = blockStatus.detail
        ? blockStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchPlotPoints = async () => {
    if (initialPlot === undefined || initialPlot.id === undefined) {
      setPlotPoints([]);
      return [];
    }
    const PlotIdStr = initialPlot.id.toString();
    if (PlotIdStr === undefined) {
      setPlotPoints([]);
      return [];
    }
    const limit = 5000;
    const offset = 0;
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("plot_id", PlotIdStr);
    const pointsStatus = await getList<PlotPoint>(
      `/plots/items/`,
      limit,
      offset,
      additionalParams
    );
    if (pointsStatus.status === SUCCESS) {
      if (pointsStatus.data !== undefined) {
        const items = listToUniqueListItems(pointsStatus.data.items);
        setPlotPoints(items);
      }
    } else {
      setPlotPoints([]);
    }
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendPlotPoints = uniqueListToListItems(plotPoints);
    toSendPlotPoints.forEach((point, index) => {
      point.blockId = editingPlot.blockId;
      point.landDevelopmentId = Number(id);
      point.order = index + 1;
    });

    if (toSendPlotPoints.length < 3) {
      errorAlert(
        "Debe indicar por lo menos 3 coordenadas para formar un área de lote"
      );
      setSubmitting(false);
      return;
    }

    let toSendPlot: Plot = {
      ...editingPlot,
      landDevelopmentId: Number(id),
      isTaken: false,
      plotPoints: toSendPlotPoints,
    };

    let requestPromise: Promise<ItemRequestStatus<Plot>>;
    if (editingPlot.id === undefined) {
      requestPromise = createItem<Plot>("/plots/create/", toSendPlot);
    } else {
      requestPromise = updateItem<Plot>(`/plots/${toSendPlot.id}/`, toSendPlot);
    }

    const plotStatus = await requestPromise;

    if (plotStatus.status !== SUCCESS) {
      if (plotStatus.errors !== undefined) {
        setErrors(plotStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (plotStatus.detail !== undefined) {
        message = plotStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingPlot(newPlot());
    setPlotPoints([]);
    setSelectedBlock(null);
  };

  useEffect(() => {
    setEditingPlot(initialPlot ? initialPlot : newPlot());
    if (initialPlot?.blockId) {
      fetchBlock(initialPlot.blockId);
    }

    fetchPlotPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPlot]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  const pointsErrors = getFieldErrors("plotPoints", errors) as Errors[];
  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow className={"mt-2"}>
            <CCol md={2}>
              <CLabel>Manzana:</CLabel>
            </CCol>
            <CCol>
              <BlockSelect
                value={selectedBlock}
                onChange={onBlockChange}
                disabled={submitting}
                landDevelopmentId={Number(id)}
              ></BlockSelect>
              <FieldErrors
                errors={getFieldErrors("blockId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Identificador:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingPlot.identifier)}
                onChange={onIdentifierChange}
                placeholder="Identificador"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>ID remota:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={editingPlot.remoteId || ""}
                min={0}
                placeholder="ID Remota API"
                onChange={onRemoteIdChange}
                step={"1"}
                key={editingPlot.remoteId}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("remoteId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Superficie (m2):</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={editingPlot.surface || ""}
                onChange={onSurfaceChange}
                step={"0.01"}
                key={editingPlot.surface}
                placeholder="Superficie"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("surface", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Plazo:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                value={editingPlot.paymentsAmount || ""}
                onChange={onPaymentAmountChange}
                step={"1"}
                key={editingPlot.paymentsAmount}
                placeholder="Plazo (Cantidad de cuotas)"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("paymentsAmount", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Precio Cuota:</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                currency={PYG}
                value={editingPlot.monthlyPayment}
                onChange={onMonthlyAmountChange}
                placeholder="Cuota"
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("monthlyPayment", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Precio Contado:</CLabel>
            </CCol>
            <CCol>
              <CurrencyField
                currency={PYG}
                value={editingPlot.totalPayment}
                onChange={onTotalAmountChange}
                placeholder="Contado"
              ></CurrencyField>
              <FieldErrors
                errors={getFieldErrors("totalPayment", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={12}>
              <h3>Coordenadas de Vertices de lote</h3>
              <h5>
                Agregue aqui en orden las coordenadas de los puntos los cuales
                definirán el área del Lote.
              </h5>
              <h5>Agregue por lo menos 3 puntos.</h5>
              <h5>
                El primer Punto añadido se conectará con el segundo, el segundo
                con el tercero y asi sucesivamente hasta el último.
              </h5>
              <h5>
                El último punto se conectará nuevamente con el primero para
                cerrar el área.
              </h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={12}>
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-fixed">
                  <thead>
                    <tr>
                      {FIXED_COLUMNS.map((title, ix) => {
                        return (
                          <th
                            className="text-center"
                            key={ix}
                            style={{
                              verticalAlign: "middle",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-inline">{title}</div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {plotPoints.map((plotPoint, ix) => {
                      return (
                        <PlotPointForm
                          disabled={submitting}
                          key={plotPoint.uuid}
                          value={plotPoint.item}
                          initialValue={plotPoint.item}
                          onDelete={() => onPlotPointItemEntryDelete(plotPoint)}
                          onChange={(item) =>
                            onPlotPointItemEntryChange({
                              uuid: plotPoint.uuid,
                              item: item,
                            })
                          }
                          errors={
                            ix < pointsErrors.length ? pointsErrors[ix] : {}
                          }
                        />
                      );
                    })}
                    {plotPoints.length === 0 ? (
                      <tr>
                        <td colSpan={FIXED_COLUMNS.length}>
                          Agregue nuevas entradas con el boton + de la derecha
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </CCol>
          </CRow>
          <CRow className="mb-1">
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
                disabled={submitting}
              >
                <i className="fa fa-plus" />
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default PlotForm;
